<template>
  <div id="simple-calendar-app">
    <div class="vx-card app-fixed-height">
      <calendar-view
        ref="calendar"
        :displayPeriodUom="calendarView"
        :show-date="showDate"
        :events="simpleCalendarEvents"
        enableDragDrop
        :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
        eventBorderHeight="0px"
        eventContentHeight="1.65rem"
        class="theme-default"
        @click-date="openAddNewEvent"
        @click-event="openEditEvent"
      >
        <div slot="header" class="mb-4">
          <div class="vx-row no-gutter">
            <!-- Month Name -->

            <!-- Current Month -->
            <div
              class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex justify-start order-start"
            >
              <div class="flex items-center">
                <feather-icon
                  icon="ChevronLeftIcon"
                  @click="updateMonth(-1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />
                <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{
                  showDate | month
                }}</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  @click="updateMonth(1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full"
                />
              </div>
            </div>

            <!-- <div
              class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex justify-start order-start"
            >
              <div class="flex items-center">
                <vs-button @click="leaveRequests()">Leave Requests</vs-button>
              </div>
            </div>

            <div class="vx-col sm:w-1/3 w-full flex justify-end">
              <vs-button
                class="rounded-r-none p-3 md:px-8 md:py-3"
                @click="calendarView = 'month'"
                >Month</vs-button
              >
              <vs-button
                class="rounded-l-none p-3 md:px-8 md:py-3"
                @click="calendarView = 'week'"
                >Week</vs-button
              >
            </div> -->
          </div>
        </div>
      </calendar-view>
    </div>

    <!-- ADD EVENT -->
    <vs-popup title="Assign Holiday" :active.sync="hrattendencepopup">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <h3>
            <b>{{ selecteddate }}</b>
          </h3>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <vs-input
            class="inputx"
            description-text="Holiday text length : 20 letters (Max)"
            maxlength="20"
            placeholder="Enter Holiday Text"
            v-model="holydayText"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <vs-button size="small" @click="uploadBoolean = true"
            >Assign Holiday</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>

    <!-- Edit EVENT -->
    <vs-popup title="Edit Holiday" :active.sync="EditHrAttendencePopup">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <h3>
            <b>{{ EditSelecteddate }}</b>
          </h3>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <vs-input
            class="inputx"
            description-text="Holiday text length : 20 letters (Max)"
            maxlength="20"
            placeholder="Edit Holiday Text"
            v-model="editHolydayText"
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <vs-button size="small" @click="editHoliday()"
            >Edit Holiday</vs-button
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <vs-button color="danger" size="small" @click="deleteHoliday()"
            >Delete Holiday</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
require("vue-simple-calendar/static/css/default.css");
import router from "@/router";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
import constants from "../../constants.json";
export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
  },
  data() {
    return {
      attendancetableheader: false,
      simpleCalendarEvents: [],
      showDate: new Date(),
      disabledFrom: false,
      id: 0,
      title: "",
      labelLocal: "none",
      selecteddate: "",
      calendarView: "month",
      hrattendencepopup: false,
      EditHrAttendencePopup: false,
      holydayText: "",
      editHolydayText: "",
      EditSelecteddate: "",
      objectsenddate: "",
      holidayDates: [],
      uploadBoolean: false,
      editid: "",
      users: [
        {
          user_id: 1,
          team_id: 11,
          name: "narendra",
          team_name: "MBW",
          leave_from_date: "02 April 2020",
          leave_to_date: "10 April 2020",
          leave_reason: "Health Problem",
          status: "pending",
        },
        {
          user_id: 2,
          team_id: 12,
          name: "surendra",
          team_name: "LMS",
          leave_from_date: "28 May 2020",
          leave_to_date: "01 June 2020",
          leave_reason: "My Engeagement Event",
          status: "pending",
        },
      ],
    };
  },
  watch: {
    uploadBoolean(value) {
      console.log("value", value);
      // this.uploadBoolean =false;
      if (value === true) {
        console.log("hello");
        this.createHoliday();
        this.getHoliday();
      }
    },
  },

  computed: {
    // simpleCalendarEvents() {
    //     return this.$store.state.calendar.simpleCalendarEvents
    // },

    disabledDatesTo() {
      return { to: new Date(this.startDate) };
    },
    disabledDatesFrom() {
      return { from: new Date(this.endDate) };
    },
    calendarLabels() {
      let calendarLabels = [
        { text: "Business", value: "business", color: "success" },
        { text: "Work", value: "work", color: "warning" },
        { text: "Personal", value: "personal", color: "danger" },
      ];
      return calendarLabels;
    },
    labelColor() {
      return (label) => {
        if (label == "business") return "success";
        else if (label == "work") return "warning";
        if (label == "personal") return "danger";
        if (label == "none") return "primary";
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  mounted() {
    // this.holidayShow();
    this.getHoliday();
  },
  methods: {
    holidayShow() {
      console.log("holidaysst", this.holidayDates);
    },
    createHoliday() {
      let momentdate = moment(this.objectsenddate, "D/M/YYYY").unix();
      let obj = {
        holiday_date: momentdate,
        name: this.holydayText,
      };
      let url = `${constants.MILES_CM_BACK}holiday`;

      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("this.finalAttendance", response);
          this.getHoliday();
          this.$vs.notify({
            title: "Updated",
            text: "Successfully updated",
            color: "success",
          });
          this.hrattendencepopup = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getHoliday() {
      let url = `${constants.MILES_CM_BACK}holiday`;
      this.uploadBoolean = false;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("holiday list", response.data.data);
          var holidayList = response.data.data;
          this.holidayDates = [];
          holidayList.forEach((element) => {
            // var dateObject = new Date(parseInt(element.holiday_date));
            // console.log("dateObject", dateObject);
            this.holidayDates.push({
              id: element.id,
              date: moment.unix(element.holiday_date).format("YYYY-MM-DD"),
              text: element.name,
            });
          });
          // this.holidayShow();
          this.simpleCalendarEvents = [];
          this.holidayDates.forEach((element) => {
            // const obj = { holidayid:element.id, title: element.text, startDate:new Date(element.date),label:null}
            // obj.classes = "event-primary"
            // this.$store.dispatch('calendar/addEventToSimpleCalendar', obj);
            this.simpleCalendarEvents.push({
              id: element.id,
              title: element.text,
              startDate: new Date(element.date),
              classes: "event-primary",
              label: null,
            });
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    updateMonth(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
    },
    openAddNewEvent(date) {
      this.submitattendancebtnhide = false;
      this.hrattendencepopup = true;

      let formatted_date =
        date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear();
      this.selecteddate = moment(formatted_date).format("ddd Do MMMM");
      this.objectsenddate =
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    },

    openEditEvent(event) {
      console.log("event id", event);
      this.EditSelecteddate = moment(event.startDate).format("ddd Do MMMM");
      this.editid = event.id;
      this.editHolydayText = event.title;
      this.objectsenddate = "";
      this.objectsenddate =
        event.startDate.getDate() +
        "-" +
        (event.startDate.getMonth() + 1) +
        "-" +
        event.startDate.getFullYear();
      this.EditHrAttendencePopup = true;
    },
    editHoliday() {
      let momentdate = moment(this.objectsenddate, "D/M/YYYY").unix();
      let obj = {
        holiday_date: momentdate * 1000,
        name: this.editHolydayText,
      };
      console.log("obj", obj);
      let url = `${constants.MILES_CM_BACK}holiday/${this.editid}`;

      axios
        .put(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("this.finalAttendance", response);
          this.getHoliday();
          this.$vs.notify({
            title: "Updated",
            text: "Successfully updated",
            color: "success",
          });
          this.EditHrAttendencePopup = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    deleteHoliday() {
      let url = `${constants.MILES_CM_BACK}holiday/${this.editid}`;

      axios
        .delete(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("this.finalAttendance", response);
          this.getHoliday();
          this.$vs.notify({
            title: "Holiday",
            text: "Successfully Deleted",
            color: "success",
          });
          this.EditHrAttendencePopup = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    leaveRequests() {
      router.push("/LeaveRequests");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/apps/simple-calendar.scss";
</style>
<style>
.vs-popup {
  max-width: 800px;
}
.app-fixed-height {
  height: calc(100vh - 9.1rem);
}
.cv-event {
  justify-content: center;
  display: flex;
}
.con-vs-popup .vs-popup {
  width: 900px;
}
.vs-con-table {
  width: 100%;
}
#simple-calendar-app {
  font-size: x-small;
}
</style>
